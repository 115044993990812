module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Portfolio of Nikolaj Schlüter Nielsen","short_name":"Schlüters portfolio","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/favicons/favicon-32x32.png","icons":[{"src":"src/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/favicons/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/favicons/safari-pinned-tab.svg","type":"image/svg+xml","sizes":"32x32","purpose":"maskable"}],"msTileColor":"#da532c","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e924fbbec225bf275e496ce9710c09cf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
